import * as Types from '../../graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

/*
* This was automatically generated on by @graphql-codegen.
* DO NOT MANUALLY EDIT !!!
* Please refer to https://github.com/wistia/wistia/blob/main/frontend/graphql/README.md for more information.
* Last Updated: Wed Apr 02 2025 16:02:13 GMT+0000 (Coordinated Universal Time)
*/

const defaultOptions = {} as const;
export type GetUserDataForFullstoryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserDataForFullstoryQuery = { __typename?: 'Query', currentAccount?: { __typename?: 'Account', id: string, displayName: string, key: string, createdAt: string, activeTrial?: { __typename?: 'Trial', id: string, plan: { __typename?: 'Plan', id: string, name: string } } | null | undefined, plan?: { __typename?: 'Plan', id: string, name: string } | null | undefined } | null | undefined, currentUser?: { __typename?: 'User', id: string, numericId: number, name?: string | null | undefined, email: string, isWadmin: boolean } | null | undefined, currentContact?: { __typename?: 'Contact', id: string, roleName?: string | null | undefined, isImpersonating: boolean, contactFunnelDetails?: { __typename?: 'ContactFunnelDetails', id: string, videoGoals?: string | null | undefined } | null | undefined } | null | undefined };


export const GetUserDataForFullstoryDocument = gql`
    query getUserDataForFullstory {
  currentAccount {
    id
    displayName
    key
    createdAt
    activeTrial {
      id
      plan {
        id
        name
      }
    }
    plan {
      id
      name
    }
  }
  currentUser {
    id
    numericId
    name
    email
    isWadmin
  }
  currentContact {
    id
    roleName
    isImpersonating
    contactFunnelDetails {
      id
      videoGoals
    }
  }
}
    `;

/**
 * __useGetUserDataForFullstoryQuery__
 *
 * To run a query within a React component, call `useGetUserDataForFullstoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDataForFullstoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDataForFullstoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserDataForFullstoryQuery(baseOptions?: Apollo.QueryHookOptions<GetUserDataForFullstoryQuery, GetUserDataForFullstoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDataForFullstoryQuery, GetUserDataForFullstoryQueryVariables>(GetUserDataForFullstoryDocument, options);
      }
export function useGetUserDataForFullstoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDataForFullstoryQuery, GetUserDataForFullstoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDataForFullstoryQuery, GetUserDataForFullstoryQueryVariables>(GetUserDataForFullstoryDocument, options);
        }
export function useGetUserDataForFullstorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserDataForFullstoryQuery, GetUserDataForFullstoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserDataForFullstoryQuery, GetUserDataForFullstoryQueryVariables>(GetUserDataForFullstoryDocument, options);
        }
export type GetUserDataForFullstoryQueryHookResult = ReturnType<typeof useGetUserDataForFullstoryQuery>;
export type GetUserDataForFullstoryLazyQueryHookResult = ReturnType<typeof useGetUserDataForFullstoryLazyQuery>;
export type GetUserDataForFullstorySuspenseQueryHookResult = ReturnType<typeof useGetUserDataForFullstorySuspenseQuery>;
export type GetUserDataForFullstoryQueryResult = Apollo.QueryResult<GetUserDataForFullstoryQuery, GetUserDataForFullstoryQueryVariables>;